import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/jenkins/agent/workspace/vOps_provision-documentation_dev/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const PageDescription = makeShortcode("PageDescription");
const Tabs = makeShortcode("Tabs");
const Tab = makeShortcode("Tab");
const Row = makeShortcode("Row");
const Column = makeShortcode("Column");
const Title = makeShortcode("Title");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <PageDescription mdxType="PageDescription">
      <p>{`The `}<inlineCode parentName="p">{`<Tabs>`}</inlineCode>{` and `}<inlineCode parentName="p">{`<Tab>`}</inlineCode>{` components are used together to display and swap
between content. Like all of the MDX components, you’ll need to give the content
you pass in a line of whitespace. When in doubt, follow the examples. Everything
should be left aligned, indenting `}<inlineCode parentName="p">{`<Tab>`}</inlineCode>{` will result in errors.`}</p>
    </PageDescription>
    <h2>{`Example`}</h2>
    <Tabs mdxType="Tabs">
      <Tab label="Just text" mdxType="Tab">
        <p>{`The tab component can be used for a variety of content. From text, to images, to
columns. If you want to have multiple columns, you’ll need to use our `}<inlineCode parentName="p">{`Row`}</inlineCode>{` and
`}<inlineCode parentName="p">{`Column`}</inlineCode>{` helpers as demonstrated in the “Two images” tab.`}</p>
      </Tab>
      <Tab label="Single image" mdxType="Tab">
        <p>{`If your content is full width, you don’t need `}<inlineCode parentName="p">{`Row`}</inlineCode>{` and `}<inlineCode parentName="p">{`Column`}</inlineCode></p>
        <span {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1152px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "30.555555555555554%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAGABQDASIAAhEBAxEB/8QAFgABAQEAAAAAAAAAAAAAAAAAAAEC/8QAFQEBAQAAAAAAAAAAAAAAAAAAAwT/2gAMAwEAAhADEAAAAboQoBv/AP/EABYQAAMAAAAAAAAAAAAAAAAAAAABFP/aAAgBAQABBQKNESIkf//EABcRAQADAAAAAAAAAAAAAAAAAAADETL/2gAIAQMBAT8Bh0t//8QAFxEAAwEAAAAAAAAAAAAAAAAAAQIQIv/aAAgBAgEBPwFTmf/EABUQAQEAAAAAAAAAAAAAAAAAAAAx/9oACAEBAAY/AlV//8QAFhAAAwAAAAAAAAAAAAAAAAAAARBh/9oACAEBAAE/IbBrf//aAAwDAQACAAMAAAAQiA//xAAZEQACAwEAAAAAAAAAAAAAAAAAAREhYZH/2gAIAQMBAT8QZ2J5xH//xAAWEQEBAQAAAAAAAAAAAAAAAAABACH/2gAIAQIBAT8QYzN//8QAGBABAQEBAQAAAAAAAAAAAAAAAQARIWH/2gAIAQEAAT8QEcsyeLw3/9k=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "color array",
            "title": "color array",
            "src": "/static/616039aad8e9f404da41a21f9fe6aef0/2e753/colors.jpg",
            "srcSet": ["/static/616039aad8e9f404da41a21f9fe6aef0/69549/colors.jpg 288w", "/static/616039aad8e9f404da41a21f9fe6aef0/473e3/colors.jpg 576w", "/static/616039aad8e9f404da41a21f9fe6aef0/2e753/colors.jpg 1152w"],
            "sizes": "(max-width: 1152px) 100vw, 1152px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
    `}</span>
      </Tab>
      <Tab label="Two images" mdxType="Tab">
        <Row mdxType="Row">
          <Column colSm={6} colLg={6} mdxType="Column">
            <p>{`Column one`}</p>
            <span {...{
              "className": "gatsby-resp-image-wrapper",
              "style": {
                "position": "relative",
                "display": "block",
                "marginLeft": "auto",
                "marginRight": "auto",
                "maxWidth": "1152px"
              }
            }}>{`
      `}<span parentName="span" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "30.555555555555554%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAGABQDASIAAhEBAxEB/8QAFgABAQEAAAAAAAAAAAAAAAAAAAEC/8QAFQEBAQAAAAAAAAAAAAAAAAAAAwT/2gAMAwEAAhADEAAAAboQoBv/AP/EABYQAAMAAAAAAAAAAAAAAAAAAAABFP/aAAgBAQABBQKNESIkf//EABcRAQADAAAAAAAAAAAAAAAAAAADETL/2gAIAQMBAT8Bh0t//8QAFxEAAwEAAAAAAAAAAAAAAAAAAQIQIv/aAAgBAgEBPwFTmf/EABUQAQEAAAAAAAAAAAAAAAAAAAAx/9oACAEBAAY/AlV//8QAFhAAAwAAAAAAAAAAAAAAAAAAARBh/9oACAEBAAE/IbBrf//aAAwDAQACAAMAAAAQiA//xAAZEQACAwEAAAAAAAAAAAAAAAAAAREhYZH/2gAIAQMBAT8QZ2J5xH//xAAWEQEBAQAAAAAAAAAAAAAAAAABACH/2gAIAQIBAT8QYzN//8QAGBABAQEBAQAAAAAAAAAAAAAAAQARIWH/2gAIAQEAAT8QEcsyeLw3/9k=')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="span" {...{
                "className": "gatsby-resp-image-image",
                "alt": "color array",
                "title": "color array",
                "src": "/static/616039aad8e9f404da41a21f9fe6aef0/2e753/colors.jpg",
                "srcSet": ["/static/616039aad8e9f404da41a21f9fe6aef0/69549/colors.jpg 288w", "/static/616039aad8e9f404da41a21f9fe6aef0/473e3/colors.jpg 576w", "/static/616039aad8e9f404da41a21f9fe6aef0/2e753/colors.jpg 1152w"],
                "sizes": "(max-width: 1152px) 100vw, 1152px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
    `}</span>
          </Column>
          <Column colSm={6} colLg={6} mdxType="Column">
            <p>{`Column two`}</p>
            <span {...{
              "className": "gatsby-resp-image-wrapper",
              "style": {
                "position": "relative",
                "display": "block",
                "marginLeft": "auto",
                "marginRight": "auto",
                "maxWidth": "1152px"
              }
            }}>{`
      `}<span parentName="span" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "30.555555555555554%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAGABQDASIAAhEBAxEB/8QAFgABAQEAAAAAAAAAAAAAAAAAAAEC/8QAFQEBAQAAAAAAAAAAAAAAAAAAAwT/2gAMAwEAAhADEAAAAboQoBv/AP/EABYQAAMAAAAAAAAAAAAAAAAAAAABFP/aAAgBAQABBQKNESIkf//EABcRAQADAAAAAAAAAAAAAAAAAAADETL/2gAIAQMBAT8Bh0t//8QAFxEAAwEAAAAAAAAAAAAAAAAAAQIQIv/aAAgBAgEBPwFTmf/EABUQAQEAAAAAAAAAAAAAAAAAAAAx/9oACAEBAAY/AlV//8QAFhAAAwAAAAAAAAAAAAAAAAAAARBh/9oACAEBAAE/IbBrf//aAAwDAQACAAMAAAAQiA//xAAZEQACAwEAAAAAAAAAAAAAAAAAAREhYZH/2gAIAQMBAT8QZ2J5xH//xAAWEQEBAQAAAAAAAAAAAAAAAAABACH/2gAIAQIBAT8QYzN//8QAGBABAQEBAQAAAAAAAAAAAAAAAQARIWH/2gAIAQEAAT8QEcsyeLw3/9k=')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="span" {...{
                "className": "gatsby-resp-image-image",
                "alt": "color array",
                "title": "color array",
                "src": "/static/616039aad8e9f404da41a21f9fe6aef0/2e753/colors.jpg",
                "srcSet": ["/static/616039aad8e9f404da41a21f9fe6aef0/69549/colors.jpg 288w", "/static/616039aad8e9f404da41a21f9fe6aef0/473e3/colors.jpg 576w", "/static/616039aad8e9f404da41a21f9fe6aef0/2e753/colors.jpg 1152w"],
                "sizes": "(max-width: 1152px) 100vw, 1152px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
    `}</span>
          </Column>
        </Row>
      </Tab>
    </Tabs>
    <h2>{`Code`}</h2>
    <Title mdxType="Title">Text</Title>
    <pre><code parentName="pre" {...{
        "className": "language-mdx",
        "metastring": "path=components/Tabs/Tabs.js src=https://github.com/carbon-design-system/gatsby-theme-carbon/tree/main/packages/gatsby-theme-carbon/src/components/Tabs",
        "path": "components/Tabs/Tabs.js",
        "src": "https://github.com/carbon-design-system/gatsby-theme-carbon/tree/main/packages/gatsby-theme-carbon/src/components/Tabs"
      }}>{`// Start with the wrapper component (Tabs)

<Tabs>

<Tab label="Text">

The tab component can be used for a variety of content. From text, to images, to
columns. If you want to have multiple columns, you’ll need to use our \`Row\` and
\`Column\` helpers as demonstrated in the "Two images" tab.

</Tab>
`}</code></pre>
    <Title mdxType="Title">Single image</Title>
    <pre><code parentName="pre" {...{
        "className": "language-mdx",
        "metastring": "path=components/Tabs/Tabs.js src=https://github.com/carbon-design-system/gatsby-theme-carbon/tree/main/packages/gatsby-theme-carbon/src/components/Tabs",
        "path": "components/Tabs/Tabs.js",
        "src": "https://github.com/carbon-design-system/gatsby-theme-carbon/tree/main/packages/gatsby-theme-carbon/src/components/Tabs"
      }}>{`<Tab label="Single image">
  If your content is full width, you don’t need \`Row\` and \`Column\` ![color
  array](images/colors.jpg)
</Tab>
`}</code></pre>
    <Title mdxType="Title">Multiple images</Title>
    <pre><code parentName="pre" {...{
        "className": "language-mdx",
        "metastring": "path=components/Tabs/Tabs.js src=https://github.com/carbon-design-system/gatsby-theme-carbon/tree/main/packages/gatsby-theme-carbon/src/components/Tabs",
        "path": "components/Tabs/Tabs.js",
        "src": "https://github.com/carbon-design-system/gatsby-theme-carbon/tree/main/packages/gatsby-theme-carbon/src/components/Tabs"
      }}>{`<Tab label="Two images">

<Row>
<Column colSm={6} colLg={6}>

Column one

![color array](images/colors.jpg)

</Column>
<Column colSm={6} colLg={6}>

Column two

![color array](images/colors.jpg)

</Column>
</Row>

</Tab>

// Make sure you close the wrapper component

</Tabs>
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      